@import 'colors';

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial/ARIAL.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@mixin dark {
  background: $bg-dark;
  color: $color-white;
  border-color: $color-white;
}
@mixin light {
  background: $bg-light;
}
