.container {
  width: 100%;
  padding: 30px;
}

@media only screen and (max-width: 600px) {
  .table_tools_container {
    display: flex;
    flex-direction: column !important;
  }
}
