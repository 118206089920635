@import './main';

*,
*::before,
*::after {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Arial';
  transition: background 300ms ease-in-out, color 200ms ease-in-out !important;
}

a {
  color: white;
  text-decoration: none;
}

:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-transparent: #ffffff00;
  --color-blue-light: #1dace3;
  --color-grey-light: #b9b8b8;
  --color-grey-dark: #696969;
  --color-slate-dark: #29303b;
  --color-blue-dark: #4e5d73;
  --color-pink-dark: #c3262d;
  --color-blue-dark: #001a49;
  --color-purple: #5a149b;
  --color-yellow: rgb(248, 248, 36);
  --color-red: red;
  --color-green: rgb(43, 180, 43);
  --color-gold: #ffc107;

  --bg-light: linear-gradient(to right, var(--color-pink-dark), var(--color-blue-dark));
  --bg-dark: linear-gradient(to right, var(--color-purple), var(--color-blue-dark));
}
