@import '../../assets/sass/common.scss';

.photo_icon {
  position: absolute;
  opacity: 0;
  z-index: 100;
  transition: opacity 0.5s ease-in-out;
  transform: scale(1.5);
}

.photo_edit_btn {
  position: relative;
}
.photo_edit_btn:hover .photo_icon {
  opacity: 1;
}
.info,
.name,
.about_info {
  color: var(--text-color);
  padding: 5px;
}
