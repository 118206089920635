@import '../../../assets/sass/common.scss';

.search_input {
  color: var(--text-color) !important;
  border-radius: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #b9b8b8;
  margin: 15px;
  display: flex;
  justify-self: flex-end;
}
.icon_btn {
  color: var(--text-color);
}
